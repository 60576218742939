<template>
    <div class="home">


        <!-- 面包屑 -->
        <!-- <div class="breadcrumb">
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/hoveContent' }">数控机床一站式服务平台首页</el-breadcrumb-item>
                <el-breadcrumb-item>全部商品</el-breadcrumb-item>
                <el-breadcrumb-item>仪器</el-breadcrumb-item>
                <el-breadcrumb-item>电机</el-breadcrumb-item>
            </el-breadcrumb>
        </div> -->
        <!-- 无结果 -->
        <div v-if="goodsList.length == 0 && !loading" style="display: flex;justify-content: center;margin-top: 100px;">
            <noResults :keyWords="typeName" :typeSouS="typeSouS"></noResults>
        </div>
        <div v-else>
            <!-- 价格排序 tabs -->
            <div class="sorting">
                <div class="tabsBox">
                    <div class="tabs" :style="{ width: sortList.length * 84 + 'px' }">
                        <div class="son" :class="[i.act ? 'tabsAct2' : '']" @click="sortCilck(index)"
                            :style="{ borderLeft: index == 1 || index == 3 ? '1px solid #e0e0e0' : '', borderRight: index == 1 ? '1px solid #e0e0e0' : '' }"
                            v-for="(i, index) in sortList" :key="index">
                            <div style="display: flex;align-items: center;justify-content: center;">
                                <div>{{ i.name }}</div>
                                <div v-if="index == 1" style="display: flex;align-items: center;margin-left: 5px;">
                                    <img :src="imagePath" style="width: 14px;" alt="">
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="my">共找到 <span style="color: #FF4242;">{{ paging.total }}</span> 条 {{ typeName }} 产品信息</div>
                </div>
            </div>

            <!-- 商品 -->
            <div class="tuijian" v-loading="loading">
                <div class="son" v-for="(i, index) in goodsList" :key="index" @click="commodityProductDetails(i.spuId)">
                    <div class="img">
                        <img :src="i.mainImage" alt="">
                    </div>
                    <div class="name">
                        {{ i.title }}
                    </div>
                    <div class="jiage">
                        <div class="l" v-if="i.spuType == 'discuss'">面议</div>
                        <div class="l" v-else>￥{{ i.price }}</div>
                        <div v-if="i.type == '2'"
                            style="width: 40px;height: 20px;border-radius: 4px;text-align: center;line-height: 20px;font-size: 12px;color: #fff;background-color: #FF4242;margin-right: 5px;">
                            定金</div>
                        <div class="r" v-if="i.sales > 10000">销量 1万+</div>
                        <div class="r" v-if="i.sales < 10000">销量 {{ i.sales }}</div>
                    </div>
                </div>
            </div>

            <!-- 分页 -->
            <!-- <div class="paging">
                <el-pagination background layout="prev, pager, next" :total="100">
                </el-pagination>
            </div> -->
            <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="paging.sizi"
                :currentPage="paging.page"></paging>
        </div>

    </div>
</template>

<script>
import { getGoodsTypeId } from "@/utils/api/homeApi/index"
import noResults from "@/pages/searchPage/noResults.vue";
import paging from "@/components/paging.vue";
export default {
    name: 'search',
    components: {
        noResults,
        paging
    },
    data() {
        return {
            sortList: [
                {
                    name: '综合',
                    act: true
                },
                {
                    name: '价格',
                    act: false
                },
                {
                    name: '销量',
                    act: false
                },
                {
                    name: '新品',
                    act: false
                },
            ],
            // 分类id
            typeId: '',
            // 分类名称
            typeName: '',
            paging: {
                sizi: 50,
                page: 1,
                total: 0
            },
            type: 'ALL',
            // 商品列表
            goodsList: [],
            // 
            loading: true,
            // 价格logo
            imagePath: require('@/assets/icon/jgpx.png')
        }
    },
    mounted() {
        this.typeId = this.$route.query.id
        this.typeName = this.$route.query.typeName
        this.getGoodsType()
    },
    methods: {
        commodityProductDetails(ids) {
            // this.$store.commit('getCommodity',ids)
            window.open(`#/commodity/productDetails?ids=${ids}`, '_blank');
        },
        sortCilck(index) {
            this.sortList.map((t) => (t.act = false));
            this.sortList[index].act = true;
            if (index == 0) {
                this.type = 'ALL'
                this.imagePath = require('@/assets/icon/jgpx.png')
            }
            if (index == 1) {
                if (this.type == 'TALL' || this.type == 'LOW') {
                    if (this.type == 'TALL') {
                        this.type = 'LOW'
                        this.imagePath = require('@/assets/icon/jgpxb.png')
                    } else {
                        this.type = 'TALL'
                        this.imagePath = require('@/assets/icon/jgpxt.png')
                    }
                } else {
                    this.type = 'TALL'
                    this.imagePath = require('@/assets/icon/jgpxt.png')
                }
            }
            if (index == 2) {
                this.type = 'SALES'
                this.imagePath = require('@/assets/icon/jgpx.png')
            }
            if (index == 3) {
                this.type = 'NEW'
                this.imagePath = require('@/assets/icon/jgpx.png')
            }
            this.loading = true
            this.paging.page = 1
            this.getGoodsType()
        },
        // 分页事件
        handleCurrentChange(val) {
            this.loading = true
            this.paging.page = val
            this.getGoodsType()
        },
        // 根据分类获取商品
        async getGoodsType() {
            let data = {
                type: this.type,
                id: this.typeId,
                currentPage: this.paging.page,
                pageSize: this.paging.sizi,
            }
            const res = await getGoodsTypeId(data)
            if (res.statusCode == 8201) {
                this.goodsList = res.data.records
                this.paging.total = res.data.total
                this.loading = false
            }
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    background-color: #fff;
    margin-top: 3px;

}

.breadcrumb {
    width: 1200px;
    margin: 0px auto;
    padding: 20px;
    box-sizing: border-box;
}

// 删选样式 tab
.brandDead {
    width: 1200px;
    height: auto;
    margin: 20px auto;
    background-color: #fff;
    padding: 10px 20px 20px 20px;
    box-sizing: border-box;
    border: 1px solid #E0E0E0;

    >.son {
        width: 100%;
        height: auto;
        font-size: 14px;
        display: flex;
        padding-bottom: 15px;
        border-bottom: 1px solid #E0E0E0;
        margin-top: 12px;
        align-items: center;

        >.title {
            width: 65px;
            color: #666;
            margin-right: 20px;
        }

        >.tabs {
            color: #333;
            display: flex;

            >.tab {
                margin-right: 20px;
                transition: all .3s;
                cursor: pointer;
            }

            >.tab:hover {
                color: #FF4242;
                transition: all .3s;
            }
        }

    }
}

// 价格排序tabs
.sorting {
    width: 1200px;
    height: auto;
    margin: 20px auto;
    border-radius: 4px;
    background-color: #F5F5F5;
    padding: 0px 10px;
    box-sizing: border-box;

    >.tabsBox {
        width: 100%;
        height: 45px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #F5F5F5;

        >.tabs {
            display: flex;
            border: 1px solid #E0E0E0;
            background-color: #fff;
            box-sizing: border-box;
            color: #333;


            >.son {
                width: 84px;
                height: 30px;
                box-sizing: border-box;
                // border: 1px solid #fff;
                text-align: center;
                line-height: 30px;
                font-size: 14px;
                text-align: center;
                transition: all .3s;
                cursor: pointer;
            }
        }

        >.my {
            font-size: 14px;
            color: #333;
        }
    }
}

// 商品
.tuijian {
    width: 1200px;
    min-height: 600px;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;

    >.son {
        width: 220px;
        height: 280px;
        background-color: #fff;
        border-radius: 4px;
        margin: 0 10px;
        margin-bottom: 20px;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.10);
        box-sizing: border-box;
        transition: all .3s;
        cursor: pointer;

        >.img {
            width: 200px;
            height: 200px;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        >.name {
            font-size: 14px;
            color: #000;
            width: 100%;
            margin-top: 10px;
            white-space: nowrap;
            transition: all .3s;
            /* 禁止换行 */
            overflow: hidden;
            /* 超出部分隐藏 */
            text-overflow: ellipsis;
            /* 超出部分显示省略号 */
        }

        >.jiage {
            width: 100%;
            height: 25px;
            margin-top: 5px;
            display: flex;
            align-items: flex-end;

            >.l {
                font-size: 16px;
                font-weight: bold;
                color: #FF4242;
                margin-right: 6px;
            }

            >.r {
                font-size: 12px;
                color: #666;
                margin-bottom: 2px;
            }
        }
    }

    >.son:hover {
        border: 1px solid #FFA5A5;
        transition: all .3s;

        >.name {
            color: #FF4242;
            transition: all .3s;
        }
    }
}

// 分页
.paging {
    width: 1200px;
    height: 50px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
}


.tabsAct {
    color: #ff4242;
    font-weight: 700 !important;
    transition: all 0.3s;
}

.tabsAct2 {
    background-color: #FF4242;
    color: #fff;
    transition: all .3s;
}

/deep/ .el-breadcrumb__inner.is-link:hover {
    color: #ff4242 !important;
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>