<template>
    <div class="home">
        <div class="img">
            <img src="@/assets/souswjg.png" alt="">
        </div>
        <div class="r">
            <div class="title" v-if="typeSouS == 'coupon'">
                很抱歉，没有找到可以使用该优惠券的商品
            </div>
            <div class="title" v-else>
                很抱歉，没有找到与“<span style="color: #FF4242;">{{ keyWords }}</span>”相关的产品信息
            </div>
            <div class="text" v-if="typeSouS !== 'coupon'">
                建议您：<br>
                缩短或修改您的搜索词，重新搜索。<br>
                没有找到符合的结果，建议您发布采购信息，让供应商来找您。发布采购信息
                联系我们的客服，帮助您查询产品信息，在线客服021-87208888。
            </div>
            <div v-if="typeSouS !== 'coupon'" class="btn" @click="fabu">发布采购信息</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['keyWords','typeSouS'],
    data() {
        return {

        }
    },
    methods: {
        fabu() {
            this.$router.push("/souurcingPublish")
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 815px;
    height: 256px;
    // position: absolute;
    // // top: 40%;
    // // bottom: 30%;
    // left: 50%;
    // transform: translateX(-50%);
    display: flex;
    align-items: center;
    transition: all .3s;

    >.img {
        width: 287px;
        height: 176px;

        >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    >.r {
        width: 500px;
        height: auto;
        margin-left: 30px;

        >.title {
            width: 100%;
            font-size: 20px;
            color: #333;
        }

        >.text {
            width: 100%;
            font-size: 14px;
            color: #666;
            margin-top: 20px;
        }

        >.btn {
            width: 138px;
            height: 36px;
            border-radius: 4px;
            background-color: #FFD79C;
            border: 1px solid #FFAB6F;
            font-size: 14px;
            color: #DA4A0D;
            text-align: center;
            line-height: 36px;
            margin-top: 30px;
            cursor: pointer;
        }
    }
}
</style>